import _ from 'lodash'
import { getModules } from '@wix/simple-module-loader'
import { getComponentsSDKLoader } from '@wix/thunderbolt-components-registry/getComponentsSDKLoader'
import { createPromise, logSdkError, logSdkWarning, createProxy } from '@wix/thunderbolt-commons'
import type { IPlatformLogger, PlatformEnvData, StorageInitData } from '@wix/thunderbolt-symbols'
import { createStorageAPI } from '../storage/storageAPI'
import type { ComponentSdksLoader, CoreSdkLoaders, CreateWixStorageAPI, WixStorageAPI } from '../types'
import { DocumentSdkFactory } from './componentsSDK/Document'
import { PlatformLogger } from './platformLogger'
import { ViewerHandlers } from './viewerHandlers'
import { UnfinishedTasks } from './unfinishedTasks'
import { ModelsApiProvider } from './modelsApiProvider'
import type { InitArgs } from './types'
import {
	PLATFORM_LOGGER,
	LOAD_COMPONENT_SDKS_PROMISE,
	ON_PAGE_WILL_UNMOUNT,
	CREATE_STORAGE_API,
	UNFINISHED_TASKS,
	VIEWER_HANDLERS,
	SESSION_SERVICE,
	GET_COMP_BY_REF_ID,
	IMPORT_SCRIPTS,
	BOOTSTRAP_DATA,
	MODULE_LOADER,
	MODELS_API,
	DEBUG_API,
} from './modules/moduleNames'
import moduleFactories from './modules'

type PlatformState = {
	createStorageApi: CreateWixStorageAPI
	loadComponentSdksPromise: Promise<ComponentSdksLoader>
}

export function createPlatformAPI() {
	const { promise: waitForInit, resolver: initDone } = createPromise<PlatformState>()

	return {
		initPlatformOnSite({ logger, platformEnvData }: { logger: IPlatformLogger; platformEnvData: PlatformEnvData }) {
			const siteStorageApi: CreateWixStorageAPI = createStorageAPI()

			initDone({
				createStorageApi: (appPrefix: string, handlers: any, storageInitData: StorageInitData): WixStorageAPI => {
					return siteStorageApi(appPrefix, handlers, storageInitData)
				},
				loadComponentSdksPromise: getComponentsSDKLoader({
					platformEnvData,
					logger,
				}) as any, // TODO: remove `as any` after https://github.com/wix-private/editor-elements/pull/3443 is merged
			})
		},

		async runPlatformOnPage({
			bootstrapData,
			importScripts,
			moduleLoader,
			invokeViewerHandler,
			modelsProviderFactory,
			sessionService,
			debugApi,
			flushPendingUpdates = _.noop,
			onPageWillUnmount,
		}: InitArgs) {
			const { createStorageApi, loadComponentSdksPromise } = await waitForInit

			const viewerHandlers = ViewerHandlers(invokeViewerHandler, bootstrapData)
			const unfinishedTasks = UnfinishedTasks(viewerHandlers)
			const logger = PlatformLogger(bootstrapData, sessionService, unfinishedTasks)
			logger.interactionStarted('initialisation')
			const modelBuilder = ModelsApiProvider(bootstrapData, modelsProviderFactory, logger)
			const modelsApi = await logger.runAsyncAndReport('getAllModels', modelBuilder.getModelApi)

			const getCompByRefId = (compId: string) =>
				createProxy((functionName: string) => (...args: any) => {
					// wait for all stores to be updated before a potential (re)render of a component.
					// specifically, when changing a state of a state box, we want the target state props to be ready.
					flushPendingUpdates()
					return handlers.platform.invokeCompRefFunction(compId, functionName, args)
				})

			const modules = await getModules({
				[DEBUG_API]: debugApi,
				[MODELS_API]: modelsApi,
				[MODULE_LOADER]: moduleLoader,
				[BOOTSTRAP_DATA]: bootstrapData,
				[IMPORT_SCRIPTS]: importScripts,
				[GET_COMP_BY_REF_ID]: getCompByRefId,
				[SESSION_SERVICE]: sessionService,
				[VIEWER_HANDLERS]: viewerHandlers,
				[UNFINISHED_TASKS]: unfinishedTasks,
				[CREATE_STORAGE_API]: createStorageApi,
				[ON_PAGE_WILL_UNMOUNT]: onPageWillUnmount,
				[LOAD_COMPONENT_SDKS_PROMISE]: loadComponentSdksPromise,
				[PLATFORM_LOGGER]: logger,
				...moduleFactories,
			})

			const { viewerHandlers: handlers } = viewerHandlers

			const {
				platformBi,
				wixSelector,
				applications,
				appsPublicApi,
				ssrCacheHints,
				fedopsWebVitals,
				controllerEvents,
				componentSdkState,
				modelPropsUpdater,
				controllersExports,
				staticEventsManager,
				componentSdksManager,
				instanceCache: sdkInstancesCache,
			} = modules

			// TODO: init all Initializable modules
			;[platformBi, fedopsWebVitals, modelPropsUpdater, appsPublicApi, ssrCacheHints].forEach((module) => module.init())

			const platformEnvData = bootstrapData.platformEnvData
			const { runApplications, createRepeatedControllers } = applications

			const reporter = {
				logSdkError,
				logSdkWarning,
			}

			const AppControllerSdkLoader = async () => {
				const { AppControllerSdk } = await import('./componentsSDK/AppController' /* webpackChunkName: "AppController.corvid" */)
				return AppControllerSdk({ controllersExports, modelsApi, controllerEvents })
			}

			const AppWidgetSdkLoader = async () => {
				const { AppControllerWithChildrenSdk } = await import('./componentsSDK/AppController' /* webpackChunkName: "AppController.corvid" */)
				return AppControllerWithChildrenSdk({ controllersExports, modelsApi, controllerEvents })
			}

			const RepeaterSdkLoader = async () => {
				const { RepeaterSdk } = await import('./componentsSDK/repeaters/Repeater' /* webpackChunkName: "Repeater.corvid" */)
				return RepeaterSdk({
					modelsApi,
					wixSelector,
					reporter,
					sdkInstancesCache,
					componentSdkState,
					platformEnvData,
					createRepeatedControllers,
					handlers,
				})
			}

			const DocumentSdkLoader = async () =>
				Promise.resolve(
					DocumentSdkFactory({
						modelsApi,
						wixSelector,
						currentPageId: bootstrapData.currentPageId,
					})
				)

			const coreSdks: CoreSdkLoaders = {
				AppController: AppControllerSdkLoader,
				AppWidget: AppWidgetSdkLoader,
				TPAWidget: AppControllerSdkLoader,
				TPASection: AppControllerSdkLoader,
				TPAMultiSection: AppControllerSdkLoader,
				TPAGluedWidget: AppControllerSdkLoader,
				tpaWidgetNative: AppControllerSdkLoader,
				Repeater: RepeaterSdkLoader,
				Document: DocumentSdkLoader,
			}
			componentSdksManager.fetchComponentsSdks(coreSdks)
			logger.interactionEnded('initialisation')

			await logger.runAsyncAndReport('runApplications', () => runApplications(modelsApi.getApplicationIds()))
			// calling it here because we need to run all the applications, register the controllers APIs, run and finish all PageReady/OnReady, before executing any static events handlers.
			// some handlers may depends on the apis being registered and onReady been called,
			staticEventsManager.triggerStaticEventsHandlers() // TODO do we need to run this is SSR?
		},
	}
}
