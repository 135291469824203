export const APPS_URLS = 'appsUrls'
export const DEBUG_API = 'debugApi'
export const LINK_UTILS = 'linkUtils'
export const MODELS_API = 'modelsApi'
export const BSI_MANAGER = 'bsiManager'
export const PLATFORM_BI = 'platformBi'
export const WARMUP_DATA = 'warmupData'
export const PLATFORM_API = 'platformApi'
export const WIX_SELECTOR = 'wixSelector'
export const APPLICATIONS = 'applications'
export const COMMON_CONFIG = 'commonConfig'
export const MODULE_LOADER = 'moduleLoader'
export const BOOTSTRAP_DATA = 'bootstrapData'
export const IMPORT_SCRIPTS = 'importScripts'
export const INSTANCE_CACHE = 'instanceCache'
export const PLATFORM_UTILS = 'platformUtils'
export const REGISTER_EVENT = 'registerEvent'
export const APPS_PUBLIC_API = 'appsPublicApi'
export const SSR_CACHE_HINTS = 'ssrCacheHints'
export const PLATFORM_LOGGER = 'platformLogger'
export const SESSION_SERVICE = 'sessionService'
export const VIEWER_HANDLERS = 'viewerHandlers'
export const LOCATION_MANAGER = 'locationManager'
export const UNFINISHED_TASKS = 'unfinishedTasks'
export const BLOCKS_APPS_UTILS = 'blocksAppsUtils'
export const FEDOPS_WEB_VITALS = 'fedopsWebVitals'
export const SET_PROPS_MANAGER = 'setPropsManager'
export const GET_COMP_BY_REF_ID = 'getCompByRefId'
export const CONTROLLER_EVENTS = 'controllerEvents'
export const CREATE_STORAGE_API = 'createStorageApi'
export const PLATFORM_BI_LOGGER = 'platformBiLogger'
export const SDK_FACTORY_PARAMS = 'sdkFactoryParams'
export const CLIENT_SPEC_MAP_API = 'clientSpecMapApi'
export const COMPONENT_SDK_STATE = 'componentSdkState'
export const MODEL_PROPS_UPDATER = 'modelPropsUpdater'
export const CONTROLLERS_EXPORTS = 'controllersExports'
export const PLATFORM_ANIMATIONS = 'platformAnimations'
export const PLATFORM_ESSENTIALS = 'platformEssentials'
export const ON_PAGE_WILL_UNMOUNT = 'onPageWillUnmount'
export const WIX_CODE_API_FACTORY = 'wixCodeApiFactory'
export const STATIC_EVENTS_MANAGER = 'staticEventsManager'
export const COMPONENT_SDKS_MANAGER = 'componentSdksManager'
export const CONSENT_POLICY_MANAGER = 'consentPolicyManager'
export const WIX_CODE_VIEWER_APP_UTILS = 'wixCodeViewerAppUtils'
export const MODULE_FEDERATION_MANAGER = 'moduleFederationManager'
export const LOAD_COMPONENT_SDKS_PROMISE = 'loadComponentSdksPromise'
export const WIX_CODE_NAMESPACES_REGISTRY = 'wixCodeNamespacesRegistry'
export const DATA_BINDING_VIEWER_APP_UTILS = 'dataBindingViewerAppUtils'
